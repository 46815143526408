import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessRoutingModule } from './business-routing.module';
import { GoogleSigninDirective } from './google-signin.directive';
import { QRPageComponent } from './qr-code/qr-code-page.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { QRCodeModule } from 'angularx-qrcode';

import { SharedModule } from './../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalComponent } from '../modal/modal.component';
import { FormsModule } from '@angular/forms';

import { BusinessDetailsComponent } from './business-details/business-details.component';
import { MenuComponent } from './menu/menu.component';
import { ExistingMenuComponent } from './menu/existing-menu/existing-menu.component';
import { CustomMenuComponent } from './menu/custom-menu/custom-menu.component';
import { CustomMenuItemComponent } from './menu/custom-menu-item/custom-menu-item.component';
import { MenuItemDetailsComponent } from './menu/menu-item-details/menu-item-details.component';
import { UserVerificationComponent } from './../../user/user-verification/user-verification.component';

// cart
import { MySharedService } from './../../services/shared.service';
import { GovernanceService } from './../../services/governance.service';
import { SettingsOptionsComponent } from './settings-options/settings-options-page.component';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { InlineSVGModule } from 'ng-inline-svg';


const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
    {
      defaultCountry: 'IN',
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    }
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};

@NgModule({
  declarations: [GoogleSigninDirective,
    QRPageComponent,
    BusinessProfileComponent,
    NgbdModalComponent,
    BusinessDetailsComponent,
    MenuComponent,
    ExistingMenuComponent,
    CustomMenuComponent,
    CustomMenuItemComponent,
    MenuItemDetailsComponent,
    SettingsOptionsComponent,
    UserVerificationComponent,
  ],
  exports: [GoogleSigninDirective],
  imports: [
    CommonModule,
    SharedModule,
    BusinessRoutingModule,
    ReactiveFormsModule,
    QRCodeModule,
    InlineSVGModule,
    NgbModule,
    FormsModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
  ],
  providers: [MySharedService,
    GovernanceService
  ],

})
export class BusinessModule { }
