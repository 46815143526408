import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Item, ItemGroup, Business } from '../../../../data-model/business.model';
import { BusinessService } from '../../../../services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from "guid-typescript";
import { MenuItemDetailsComponent } from '../menu-item-details/menu-item-details.component';

@Component({
  selector: 'app-custom-menu-page',
  templateUrl: './custom-menu.component.html',
  styleUrls: ['./custom-menu.component.scss']
})

export class CustomMenuComponent implements OnInit {

  @Input()
  business: Business;

  @Output() itemChanged: EventEmitter<Item> = new EventEmitter();

  activeTab = '';
  name: string = '';  // check for length

  constructor(public afAuth: AngularFireAuth,
    public businessService: BusinessService,
    private modalService: NgbModal,
  ) {
  }


  onResize(event) {
  }

  ngOnInit() {
    // initialize customMenu is not present
    if (!this.business.customMenu) {
      this.business.customMenu = {
        itemGroup: []
      }
    } else {
      this.activeTab = this.business.customMenu?.itemGroup[0]?.name;
    }
    if (!this.business.staticMenu) {
      this.business.staticMenu = {
        files: []
      }
    }


    // do not show deleted priority
    // todo removed it before comiming here. 
    this.business.customMenu.itemGroup = this.business.customMenu.itemGroup.filter((group) => {
      return !(group.priority < 0);
    });

  }


  deleteItemType() {
    // Get current selected tag and delete it
    console.log("deleting item group : " + this.activeTab);
    for (let i = 0; i < this.business.customMenu.itemGroup.length; i++) {
      if (this.business.customMenu.itemGroup[i].name === this.activeTab) {
        this.business.customMenu.itemGroup[i].priority = -999;
        this.businessService.setCustomMenu(this.business.customMenu);
        this.businessService.updateBusinessInfoSecure();        
      }
    }

    this.business.customMenu.itemGroup = this.business.customMenu.itemGroup.filter((group) => {
      return !(group.priority < 0);
    })

    this.activeTab = this.business.customMenu.itemGroup[0] ? this.business.customMenu.itemGroup[0].name : '';
  }

  addItemType() {
    // add item type for menu  example deserts, starter, drinks
    if (this.name === '') {
      // TODO tell user that it can not be empty 
      return;
    }

    console.log("adding item");
    let newItemGroup: ItemGroup = {
      name: this.name,
      id: Guid.create().toString(),
      priority: this.business.customMenu.itemGroup.length,
      items: []
    };
    this.business.customMenu.itemGroup.push(newItemGroup);
    this.name = '';   
  }

  addItem(indexOfelement: number) {

    console.log("adding at index" + indexOfelement);
    console.log("adding item int group " + this.business.customMenu.itemGroup[indexOfelement].name);
    let newItem: Item = {
      id: Guid.create().toString(),
      priority: this.business.customMenu.itemGroup[indexOfelement].items.length
    }
    this.business.customMenu.itemGroup[indexOfelement].items.push(newItem);

    this.openMenuItemDetails(newItem);
  }

  // -- item values is update
  itemChangedHandler(updatedItem: Item) {
    // Emite to parent
    this.itemChanged.emit(updatedItem)
  }

  openMenuItemDetails(item: Item) {
    const modalRef = this.modalService.open(MenuItemDetailsComponent);
    modalRef.componentInstance.business = this.business;
    modalRef.componentInstance.item = item;
  }
}
