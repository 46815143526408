
<div class="modal-header">
    <h5 class="modal-title text-center">Menu</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body"> 


        <!-- Menu Modal contents  -->
        <h6>Select Menu Type</h6> 
                
            <div *ngFor="let radiobutton of radioItems">
                <label>
                <input type="radio" name="options" (click)="onMenuOptionChanged(radiobutton)"
                [checked]="radiobutton === model.option"> {{radiobutton}}
                </label>
            </div>
            
            <div *ngIf="model.option === 'Upload existing'">
                <app-existing-menu-page (fileChanged)="fileChangedHandler($event)" [business]='business'  ></app-existing-menu-page>
            </div>

            <div *ngIf="model.option === 'Create custom'">
                <app-custom-menu-page (itemChanged)="itemChangedHandler($event)" [business]='business'></app-custom-menu-page>
            </div>
        <!-- Menu Modal contents ends -->

</div>

<div class="modal-footer">
    <div class="left-side">
        <button type="button" class="btn btn-success btn-link" (click)="onSubmit()">Save Changes</button>
    </div>
    <div class="divider"></div>
    <div class="right-side">
        <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Cancel</button>
    </div>
</div>