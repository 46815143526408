import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { Item, Business } from '../../../../data-model/business.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from '../../../../services/file-upload.service';

@Component({
  selector: 'app-menu-item-details',
  templateUrl: './menu-item-details.component.html',
  styleUrls: ['./menu-item-details.component.scss']
})
export class MenuItemDetailsComponent implements OnInit {
  @Input()
  business: Business;

  @Input()
  item: Item;

  qrdata: string;
  selectedFile: any;


  constructor(public activeModal: NgbActiveModal,
    public fileUploadService: FileUploadService
  ) { }

  ngOnInit() {
  }

  uploadFile(priority: number) {

    this.fileUploadService.uploadFile(this.business, this.selectedFile).then((downloadURL) => {
      this.item.url = <string>downloadURL;
      console.log("uploaded file url : " + downloadURL);
    }).catch((err) => { console.log("uploaded error : " + err) });
  }

  deleteFile(priority: number) {

  }

  // detech for file upload 
  detectFiles(event) {
    this.selectedFile = event.target.files[0];

    //-- upload selected file and get url to save 
    if (this.selectedFile) {
      this.uploadFile(this.item.priority);
    };

  }

  getPic() {
    if (!this.item.url) {
      return 'none';
    }

    return 'url(' + this.item.url + ')';
  }

  deletePic() {
    this.item.url = '';
  }

}
