<div class="modal-header">
    <h5 class="modal-title text-center">Business Details</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <!-- form body -->
    <div class="container">
        <div class="row">
            <div class="col-md-8 mr-auto ml-auto">

                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="row">
                        <label>Business Name</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="businessName" placeholder="Name"
                                (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>Phone Number</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="phone" placeholder="Phone number"
                                (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>Address</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="addressLine1"
                                placeholder="Address line 1" (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="addressLine2"
                                placeholder="Address line 2" (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>City</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="city" placeholder="city"
                                (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>State</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="state" placeholder="state"
                                (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>Zipcode</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="postCode" placeholder="zipcode"
                                (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>Country</label>
                        <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> <i class="nc-icon nc-email-85"></i></span>
                            </div>
                            <input type="text" class="form-control" formControlName="country" placeholder="country"
                                (focus)="focus1=true" (blur)="focus1=false">
                        </div>
                    </div>

                    <div class="row">
                        <label>Slogan</label>
                        <textarea class="form-control" formControlName="slogan" rows="4"
                            placeholder="Tell us some tag line or catch phrase about restaurant..."></textarea>
                    </div>

                </form>

            </div>
        </div>
    </div>


</div>
<div class="modal-footer">
    <div class="left-side">
        <button type="button" class="btn btn-success btn-link" [disabled]="form.invalid " (click)="onSubmit()">Save
            Changes</button>
    </div>
    <div class="divider"></div>
    <div class="right-side">
        <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Cancel</button>
    </div>
</div>