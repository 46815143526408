import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { Order, OrderStatus, Status, TaxCalc } from '../data-model/order.model';
import { Item } from '../data-model/business.model';
import { UserService } from './user.service';
import { UserModel } from '../data-model/user.model';

@Injectable({
  providedIn: 'root'
})
export class GovernanceService {
  constructor(private afAuth: AngularFireAuth,
    private db: AngularFirestore) { }

  /**
   * Creates a new order for business
   */
  async createNeworder(businessId: string, data: Order) {
    const user = await this.afAuth.currentUser;
    return this.db.collection('business').doc(businessId).collection('orders').add({
      ...data,
      uid: user.uid,

    });
  }

  /*
  * Get orders for business
  * TODO 
  *  1. Add sorting/pagination and some filters to retrive only relevant data
  */
  getOrders(businessId: string) {
    const collectionStr = 'business/' + businessId + '/orders';
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db
            .collection<Order>(collectionStr)
            .valueChanges({ idField: 'id' });
        } else {
          return [];
        }
      }),
      // map(boards => boards.sort((a, b) => a.priority - b.priority))
    );
  }

  /*
 * Get orders for business
 * TODO 
 *  1. Add sorting/pagination and some filters to retrive only relevant data
 */
  getOrdersForBusiness(businessId: string) {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db
            .collectionGroup<Order>('orders', ref =>
              ref.where('businessId', '==', businessId)
            )
            .valueChanges({ idField: 'id' });
        } else {
          return [];
        }
      }),
    );
  }

  /*
* Get orders for user
* TODO 
*  1. Add sorting/pagination and some filters to retrive only relevant data
*/
  getOrdersForCustomer(customerId: string) {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db
            .collectionGroup<Order>('orders', ref =>
              ref.where('custoemrId', '==', customerId)
            )
            .valueChanges();
        } else {
          return [];
        }
      }),
    );
  }

  /*
  * Get customers for business
  * TODO 
  *  1. Add sorting/pagination and some filters to retrive only relevant data
  */ 
  getCustomersForBusiness(businessId: string) {
    let customerData: UserModel[] = [];
    return new Promise(async (resolve, reject) => {

      let userInfoPromises: Promise<any>[] = [];
      this.db.collectionGroup<Order>('orders', ref =>
        ref.where('businessId', '==', businessId)
      ).get().toPromise()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            console.log(doc.id, " => ", doc.data().customerId);

            // Get userinfo
            const newPromise = this.db.collection<UserModel>('userinfo', ref =>
              ref.where('uid', '==', doc.data().customerId)
            ).get().toPromise();

            userInfoPromises.push(newPromise);

            newPromise
              .then((userData) => {
                userData.forEach((data) => {
                  console.log(data.id, " => ", data.data());
                  customerData.push(data.data());
                });
              })
          });

          Promise.all(userInfoPromises).then(() => {
            resolve(customerData);
          })

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          reject(customerData);
        });

    });
  }


  createOrder(businessId: string, items: Item[], customerId: string, customerInfo: string, comment: string, orderNumber: string, subTotal: number, grandTotal: number, taxCalculations: TaxCalc[] = [], tableNumber: string = "0"): Order {

    // --  Initialze with order status as created;
    const timeNow = new Date();
    const orderStatus: OrderStatus = {
      status: Status.CREATED,
      changedBy: customerInfo,
      time: timeNow,
      isActive: true,
      isCompleted: true
    }
    const orderStatusCollection = [orderStatus];
    const newOrder: Order = {
      customer: customerInfo,
      customerId: customerId,
      businessId: businessId,
      items: items,
      orderStatus: orderStatusCollection,
      currentStatus: Status.CREATED,
      createdTime: timeNow,
      lastUpdatedTime: timeNow,
      orderNumber: orderNumber,
      tableNumber: tableNumber,
      subTotal: subTotal,
      grandTotal: grandTotal,
      taxCalculation: taxCalculations
    }
    return newOrder;
  }

  updateStatus(order: Order, customerInfo: string, comment: string, status: Status): Order {

    // --  Initialze with order status as created;
    const timeNow = new Date();
    const orderStatus: OrderStatus = {
      status: status,
      changedBy: customerInfo,
      time: timeNow,
      isActive: true,
      isCompleted: true
    }
    order.orderStatus.push(orderStatus);

    order.currentStatus = status;
    order.lastUpdatedTime = timeNow;
    return order;
  }
}
