import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserModel } from '../module/auth';
import { Business } from '../data-model/business.model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  // file upload 
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private afs: AngularFirestore,
    private storage: AngularFireStorage) {

  }

  public async uploadFile(business: Business, selectedFile: FileList) {
    const myTest = this.afs.collection('business').ref.doc();
    console.log(myTest.id)

    const file = selectedFile
    const filePath = `${business.uid}/${myTest.id}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    const user = await this.afAuth.currentUser;

    this.uploadPercent = task.percentageChanges();

    return new Promise((resolve, reject) => {

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().toPromise().then((url) => {
            this.downloadURL = url;
            resolve(this.downloadURL);
            // myTest.set({              
            //   url : this.downloadURL,
            //   myId : myTest.id,
            //   uid : user.uid,
            //   priority : filePriority
            // }) 

            console.log(this.downloadURL)
          }).catch(err => {
            reject(err);
            console.log(err)
          });
        })
      )
        .subscribe()

    });
  }

  public async uploadUserFile(userData: UserModel, selectedFile: FileList) {
    const myTest = this.afs.collection('userinfo').ref.doc();
    console.log(myTest.id)

    const file = selectedFile
    const filePath = `${userData.uid}/${myTest.id}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    const user = await this.afAuth.currentUser;

    this.uploadPercent = task.percentageChanges();

    return new Promise((resolve, reject) => {

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().toPromise().then((url) => {
            this.downloadURL = url;
            resolve(this.downloadURL);
          
            console.log(this.downloadURL)
          }).catch(err => {
            reject(err);
            console.log(err)
          });
        })
      )
        .subscribe()

    });
  }
}
