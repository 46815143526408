import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Business } from '../../../../data-model/business.model';
import { FileObject } from '../../../../data-model/business.model';

import { BusinessService } from '../../../../services/business.service';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  id: number;
  url?: string;
}


import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { FileUploadService } from '../../../../services/file-upload.service';

@Component({
  selector: 'app-existing-menu-page',
  templateUrl: './existing-menu.component.html',
  styleUrls: ['./existing-menu.component.scss']
})
export class ExistingMenuComponent implements OnInit {

  @Input()
  business: Business;

  MAX_NUMBER_OF_STATIC_MENU_FILES_ALLOWED = 20;
  @Output() fileChanged: EventEmitter<FileObject> = new EventEmitter();

  sub: Subscription;
  form: FormGroup;
  qrdata: string;

  files: FileObject[] = [];

  // file upload 
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  selectedFile: FileList | null;

  forma: FormGroup;
  tests: Observable<any[]>;
  menuFiles: FileObject[];


  constructor(public afAuth: AngularFireAuth,
    public businessService: BusinessService,
    public fileUploadService: FileUploadService) { }


  onResize() {
  }

  ngOnInit() {

    // MAX_NUMBER_OF_STATIC_MENU_FILES_ALLOWED
    for (let i = 0; i < this.MAX_NUMBER_OF_STATIC_MENU_FILES_ALLOWED; i++) {
      let item: FileObject = {
        priority: i
      }
      this.files.push(item);
    }

    // populate from restaurant data.
    this.business.staticMenu?.files.forEach((item) => {
      this.files.forEach((val) => {
        if (val.priority === item.priority) {
          val.url = item.url;
          val.id = item.id;
          val.myId = item.myId;
          val.uid = item.uid
        }
      });
    });

  }

  // file upload 
  detectFiles(event, file) {
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      this.uploadFile(file);
    }
  }

  // Just make priority to negative and save so that
  // we can run analytics over the period of time.
  deleteFile(file: FileObject) {
    this.business.staticMenu.files.forEach((fileObj) => {
      if (fileObj.url === file.url) {
        fileObj.priority = -999;
        this.deletePic(file);
      }
    })

    file.url = null;
    file.priority = -999;

    // update restaurant info  
    this.businessService.setStaticMenu(this.business.staticMenu);
    this.businessService.updateBusinessInfoSecure();
  }

  uploadFile(file: FileObject) {

    this.fileUploadService.uploadFile(this.business, this.selectedFile).then((downloadURL) => {
      file.url = <string>downloadURL;

      this.business.staticMenu.files.push(file);
      this.fileChanged.emit(file);
      console.log("uploaded file url : " + downloadURL);
    }).catch((err) => { console.log("uploaded error : " + err) });
  }

  getPic(file: any) {
    if (!file.url) {
      return 'none';
    }

    return 'url(' + file.url + ')';
  }

  deletePic(file: any) {
    file.url = '';
  }
}
