import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { Business } from '../../../data-model/business.model';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-qr-code-page',
  templateUrl: './qr-code-page.component.html',
  styleUrls: ['./qr-code-page.component.scss']
})
export class QRPageComponent implements OnInit {
  @Input()
  business: Business;
  qrdata: string;
  tableCount: any;
  selectedTable: any;
  qrdataFirst: any;
  @ViewChild('qrcodesection') qrcodesection: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  constructor(public activeModal: NgbActiveModal,
    public afAuth: AngularFireAuth) {
  }

  ngOnInit() {

    // Example URL 
    let currentUrl = window.location.href;
    let endurl = "restaurant?id=";
    this.qrdata = currentUrl.substring(0, currentUrl.lastIndexOf('/') + 1) + endurl + this.business?.id;
    this.qrdataFirst = this.qrdata;
    const count = Number(this.business.settings.numberOfTables);
    this.tableCount = new Array(count);
    for (let i = 0; i <= count; i++) {
      this.tableCount[i] = i;
    }
    this.selectedTable = 0;
  }


  saveAsImage() {

    this.tableNumberChanged();
    html2canvas(this.qrcodesection.nativeElement).then(canvas => {
      canvas.style.marginTop = "0";
      canvas.style.height = "100%";
      this.canvas.nativeElement.src = canvas.toDataURL();

      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'qr-code.png';
      this.downloadLink.nativeElement.click();
    });
  }


  onSlide(event: any) {
    const imageIndex = parseInt(event.current.replace("slideId_", ""), 10);
    this.qrdata = this.qrdata + '&table' + imageIndex;
  }

  tableNumberChanged() {
    if (this.selectedTable !== 0) {
      this.qrdata = this.qrdataFirst + '&table=' + this.selectedTable;
    } else {
      this.qrdata = this.qrdataFirst;
    }
  }

}
