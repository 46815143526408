import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay , filter} from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    private toggleButton: any;
    private sidebarVisible: boolean  = false;    
    param: any;
    hide: any;

    private _router: Subscription;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;

  constructor(private breakpointObserver: BreakpointObserver,
             public afAuth: AngularFireAuth,
             private element : ElementRef,
             public location: Location,
             private route: ActivatedRoute,
             private router: Router ,
             private renderer : Renderer2, 
             @Inject(DOCUMENT,) private document: any
             ) {
                this.sidebarVisible = false;
             }
  
             
  ngOnInit() {

    var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        if (window.outerWidth > 991) {
            window.document.children[0].scrollTop = 0;
        }else{
            window.document.activeElement.scrollTop = 0;
        }
        this.navbar.sidebarClose();
    });
    this.renderer.listen('window', 'scroll', (event) => {
        const number = window.scrollY;
        if (number > 150 || window.pageYOffset > 150) {
            // add logic
            navbar.classList.remove('navbar-transparent');
        } else {
            // remove logic
            navbar.classList.add('navbar-transparent');
        }
    });
    var ua = window.navigator.userAgent;
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    if (version) {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('ie-background');

    } 

}

setToggleButton() {     
    const navbar2: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar2.getElementsByClassName('navbar-toggler')[0];
 }

sidebarOpen() {

    if(!this.toggleButton) this.setToggleButton();

    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    // console.log(toggleButton, 'toggle');

    setTimeout(function(){
        toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
};
sidebarClose() {
    if(!this.toggleButton) this.setToggleButton();

    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
};
sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
        this.sidebarOpen();
    } else {
        this.sidebarClose();
    }
};
 
removeFooter() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice( 1 );
    if(titlee === 'signup' || titlee === 'nucleoicons'){
        return false;
    }
    else {
        return true;
    }
}
 
isHome() {
  var titlee = this.location.prepareExternalUrl(this.location.path());
  if(titlee.charAt(0) === '#'){
      titlee = titlee.slice( 1 );
  }
    if( titlee === '/home' ) {
        return true;
    }
    else {
        return false;
    }
}

isGovernance() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }
      if( titlee === '/governance' ) {
          return true;
      }
      else {
          return false;
      }
  }

isDocumentation() {
  var titlee = this.location.prepareExternalUrl(this.location.path());
  if(titlee.charAt(0) === '#'){
      titlee = titlee.slice( 1 );
  }
    if( titlee === '/documentation' ) {
        return true;
    }
    else {
        return false;
    }
}
}

