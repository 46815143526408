<div *ngIf="business" class="card card-custom bg-gray-100 card-stretch gutter-b">
  <!-- Header -->
  <div class="card-header border-0 bg-danger py-5">
    <h3 class="card-title font-weight-bolder text-white">{{business.name}}</h3>
    <div class="card-toolbar">
      <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline" title="Quick actions">
      </div>
    </div>
  </div>
  <!-- </div> -->
  <div class="card-body">

    <!-- begin: Filtration form -->
    <div class="form form-label-right">
      <div class="form-group row">
        <!-- <div class="col-lg-4"> -->
        <div class="card">
          <div class="card card-custom gutter-b">
            <div class="card card-custom card-stretch gutter-b">
              <!-- Header -->
              <div class="card-header border-0 mt-4">
                <div class="form-group row">

                  <h3 class="card-title align-items-start flex-column">

                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
                      <p class="description">
                        Address :
                        {{ business.address.addressLine1 }}
                        {{ business.address.addressLine2 }}
                        {{ business.address.city }}, {{ business.address.country }} -
                        {{ business.address.postCode }}
                      </p>
                    </span>
                  </h3>

                </div>

              </div>
              <!-- Body -->
              <div class="card-body pt-8">

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"></label>
                  <div class="col-lg-9 col-xl-6">
                    <div class="image-input image-input-outline" id="kt_profile_avatar"
                      [class.image-input-empty]="!business.logoUrl">
                      <div class="image-input-wrapper" [style.backgroundImage]="getPic()"></div>
                      <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                        <i class="fa fa-pen icon-sm text-muted"></i>

                        <input type="file" name="profile_avatar" (change)="detectFiles($event)"
                          accept=".png, .jpg, .jpeg" />
                        <input type="hidden" name="profile_avatar_remove" formControlName="pic" />
                      </label>
                      <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                      <span *ngIf="business.logoUrl"
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        (click)="deletePic()" data-action="remove" data-toggle="tooltip" title=""
                        data-original-title="Remove avatar">
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    </div>
                    <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                  </div>
                </div>


                <!--begin::Item-->
                <div class="d-flex align-items-center mb-10">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light-primary mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
                        class="svg-icon svg-icon-lg svg-icon-primary"></span>
                    </span>
                  </div>
                  <!--end::Symbol-->

                  <!--begin::Text-->
                  <div class="d-flex flex-column font-weight-bold">
                    <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
                      (click)="openBusinessDetails()">Update Business Details</a>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->

                <!--begin::Item-->
                <div class="d-flex align-items-center mb-10">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light-warning mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'"
                        class="svg-icon svg-icon-lg svg-icon-warning"></span>
                    </span>
                  </div>
                  <!--end::Symbol-->

                  <!--begin::Text-->
                  <div class="d-flex flex-column font-weight-bold">
                    <a class="text-dark-75 text-hover-primary mb-1 font-size-lg cursor-pointer"
                      (click)="openMenuDetails()">Update Menu</a>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->

                <!--begin::Item-->
                <div class="d-flex align-items-center mb-10">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light-success mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="
                                            './assets/media/svg/icons/Communication/Group-chat.svg'
                                          " class="svg-icon svg-icon-lg svg-icon-success"></span>
                    </span>
                  </div>
                  <!--end::Symbol-->

                  <!--begin::Text-->
                  <div class="d-flex flex-column font-weight-bold">
                    <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
                      (click)="openSelectThemeOption()">Settings options</a>
                  </div>
                  <!--end::Text-->

                </div>
                <!--end::Item-->


                <!--begin::Item-->
                <div class="d-flex align-items-center mb-10">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light-success mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="
                                            './assets/media/svg/icons/Communication/Group-chat.svg'
                                          " class="svg-icon svg-icon-lg svg-icon-success"></span>
                    </span>
                  </div>
                  <!--end::Symbol-->

                  <!--begin::Text-->
                  <div class="d-flex flex-column font-weight-bold">
                    <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
                      (click)="openQRCode()">Download QR Code</a>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->

              </div>
            </div>
            <div *ngIf="!business" class="card-body">
              <div class="row"><button class="btn btn-primary btn-small action-button"
                  (click)="openBusinessDetails()">Add Business</button></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="!business" class="card-body">
  <div class="row"><button class="btn btn-primary btn-small action-button" (click)="openBusinessDetails()">Add
      Business</button></div>
</div>