<footer class="footer">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
                    <li><a href="http://blog.creative-tim.com">Blog</a></li>
                    <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{test }}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                </span>
            </div>
        </div>
    </div>
</footer>
