import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './user/auth.guard';
import { RestaurantHomeComponent } from './user/restaurant-home/restaurant-home.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
    import('./module/auth/auth.module').then((m)=> m.AuthModule) 
  },
  {
    path: 'restaurant',
    loadChildren: () =>
    import('./user/restaurant-home/restauranthome.module').
    then((m)=> m.RestaurantHomeModule) 
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
