<div *ngIf="item.priority >= 0" class="card-body pt-2">
    <!--begin::Item-->
    <div class="d-flex flex-wrap align-items-center mb-10">
        <!--begin::Symbol-->
        <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
            <div class="symbol-label" style="
              background-image: url('{{item.url}}');
            "></div>
        </div>
        <!--end::Symbol-->

        <!--begin::Title-->
        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
            <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                {{item.name}}
            </a>
            <span class="text-muted font-weight-bold font-size-sm my-1">
                {{item.description}}
            </span>
            <span class="text-muted font-weight-bold font-size-sm">
                Price: <span class="text-primary font-weight-bold">{{item.price}}</span>
            </span>
        </div>

        <div ngbDropdown class="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions"
            data-placement="left">
            <a ngbDropdownToggle class="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="ki ki-bold-more-hor"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right">
                <ul class="navi navi-hover">
                    <li class="navi-header pb-1">
                        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
                            (click)="onEdit(item)">Edit</span>
                    </li>
                </ul>
                <ul class="navi navi-hover">
                    <li class="navi-header pb-1">
                        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
                            (click)="onDelete()">Delete</span>
                    </li>
                </ul>
            </div>
        </div>


    </div>
</div>