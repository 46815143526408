<div class="modal-header">
  <h5 class="modal-title text-center">Item</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!--  -->
<div clas="container">
  <div class="row">

    <div class="col-md-5">
      <div class="form-group row">
        <img class="gallery-image">

        <div class="image-input image-input-outline" id="kt_profile_avatar" [class.image-input-empty]="!item.url"
          [style.backgroundImage]="'url(./assets/media/users/blank.png)'">
          <div class="image-input-wrapper" [style.backgroundImage]="getPic()"></div>
          <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change"
            data-toggle="tooltip" title="" data-original-title="Change avatar">
            <i class="fa fa-pen icon-sm text-muted"></i>

            <input type="file" name="profile_avatar" (change)="detectFiles($event)" accept=".png, .jpg, .jpeg" />
            <input type="hidden" name="profile_avatar_remove"  />
          </label>
          <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel"
            data-toggle="tooltip" title="" data-original-title="Cancel avatar">
            <i class="ki ki-bold-close icon-xs text-muted"></i>
          </span>
          <span *ngIf="item.url" class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            (click)="deletePic()" data-action="remove" data-toggle="tooltip" title=""
            data-original-title="Remove avatar">
            <i class="ki ki-bold-close icon-xs text-muted"></i>
          </span>
        </div>

      </div>
    </div>
    <div class="form-group row">

      <div class="col-md-6">
        <div class="row">
          <label>Name</label>
          <input [(ngModel)]="item.name" #ctrl="ngModel" required>
        </div>
        <div class="row">
          <label>Description</label>
          <input [(ngModel)]="item.description" #ctrl="ngModel" required>
        </div>
        <div class="row">
          <label>Price</label>
          <input [(ngModel)]="item.price" #ctrl="ngModel" required>
        </div>
      </div>
      <div class="form-group row">

      </div>
    </div>
    <!--  -->

    <div class="modal-footer">
      <div class="right-side">
        <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Save &
          Close</button>
      </div>
    </div>