import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItemDetailsComponent } from '../menu-item-details/menu-item-details.component';

import { CustomMenu, Item, ItemGroup, Business } from '../../../../data-model/business.model';
import { BusinessService } from '../../../../services/business.service';

@Component({
  selector: 'app-custom-menu-item-page',
  templateUrl: './custom-menu-item.component.html',
  styleUrls: ['./custom-menu-item.component.scss']
})
export class CustomMenuItemComponent  implements OnInit {

  @Input() item: Item;

  @Input() 
  business: Business;
  
  @Output() itemChanged: EventEmitter<Item> =   new EventEmitter();

  constructor(public afAuth: AngularFireAuth,
              public businessService: BusinessService,
              private modalService: NgbModal) {                   
              }

  ngOnInit() {         
  }
  onDelete(){
    //--  set item priority to negative 
    this.item.priority = -999;
    this.itemChanged.emit(this.item);
  }

  onEdit(item: Item) {
    const modalRef = this.modalService.open(MenuItemDetailsComponent);
    modalRef.componentInstance.business = this.business;
    modalRef.componentInstance.item = item;
  }
}
