<div class="page-header" data-parallax="true" style="background-image: url('./assets/img/daniel-olahh.jpg');">
    <div class="filter"></div>
    <div class="container">
        <div class="motto text-center">
            <h1>Contactless is the new normal</h1>
            <h3>Contactless QR code menus for small restaurants, cafés and bars. Show your guests and staff you care about their safety.
            </h3>
           <button class="btn btn-outline-neutral btn-round btn-lg"> 
               <a [routerLink]="['/login']" >Get Started for free today</a>
           </button>
            <br />
            <!-- <a href="#" class="btn btn-outline-neutral btn-round"><i class="fa fa-play"></i>Watch video</a>
            <button type="button" class="btn btn-outline-neutral btn-round">Download</button> -->
        </div>
    </div>
</div>
<div class="main">
    <div class="section text-center">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
                <h2 class="title">Let's talk product</h2>
                <h5 class="description">
                    Going Digital is New Normal Now
Many Countries and their state governments are mandating that restaurants turn to disposable menus to help protect restaurant visitor against the spread of  Covid19.

 <!-- Digital Menu Saves Cost and Its Safe as No customer will be touching the menu
 Protect Your Employees and Visitors from spreading corona virus through disposable menus
 Make Best use of technology and show the menu directly on visitors phone or tablet
 Digital Menu can be changed at any time. There is no more printing cost every time you make a change
 QR Code generated once, stays for ever so there is no need to change/ print QR codes every time after menu changes
 Show to your visitors that you adopt technology and care of them -->
                </h5>
                <br>
            </div>
        </div>
        <br/><br/>
        <div class="row">
            <div class="col-md-3">
                <div class="info">
                    <div class="icon icon-danger">
                        <i class="nc-icon nc-album-2"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title">Pick Your Existing Menu  </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info">
                    <div class="icon icon-danger">
                        <i class="nc-icon nc-bulb-63"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title">Scan or Take Picture of the Menu
                        </h4>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info">
                    <div class="icon icon-danger">
                        <i class="nc-icon nc-chart-bar-32"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title">Upload Images
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info">
                    <div class="icon icon-danger">
                        <i class="nc-icon nc-sun-fog-29"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title">Get QR Code
                        </h4>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

    <div class="section landing-section section-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mr-auto ml-auto">
                    <h2 class="text-center">Keep in touch?</h2>
                    <form class="contact-form">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Name</label>
                                <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="nc-icon nc-single-02"></i></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Name" (focus)="focus=true" (blur)="focus=false" >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Email</label>
                                <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">  <i class="nc-icon nc-email-85"></i></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Email" (focus)="focus1=true" (blur)="focus1=false" >
                                </div>
                            </div>
                        </div>
                        <label>Message</label>
                        <textarea class="form-control" rows="4" placeholder="Tell us your thoughts and feelings..."></textarea>
                        <div class="row">
                            <div class="col-md-4 mr-auto ml-auto">
                                <button class="btn btn-danger btn-lg btn-fill">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
