import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  CommonModule} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// App Modules
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomePageComponent } from './home-page/home-page.component';
import { NavbarComponent } from './shared/shell/navbar/navbar.component';
import { FooterComponent } from './shared/shell/footer/footer.component';

import { SharedModule } from './shared/shared.module';
import { BusinessModule } from './module/business/business.module';
import { QRCodeModule } from 'angularx-qrcode';

// Firebase imports
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireStorageModule } from  '@angular/fire/storage';

import { TranslateModule } from '@ngx-translate/core';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThermalPrintModule } from 'ng-thermal-print'; //add this line

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    BusinessModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    QRCodeModule,
    AngularFireStorageModule,
    CommonModule,
    HttpClientModule,
    SplashScreenModule,
    NgbModule, 
    ThermalPrintModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
