  <nav class="navbar navbar-expand-lg fixed-top navbar-transparent" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" href="https://www.creative-tim.com">QR Code</a>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">                   
                <li class="nav-item" *ngIf="!isHome()">
                    <a [routerLink]="['/home']" *ngIf="!isDocumentation()" class="nav-link"><i class="nc-icon nc-layout-11"></i>Home</a>
                    <a [routerLink]="['/home']" *ngIf="isDocumentation()" class="nav-link">Back to Kit</a>
                </li>
                <li class="nav-item" *ngIf="isDocumentation()">
                    <a href="https://github.com/creativetimofficial/paper-kit-2-angular/issues?ref=pk2-free-local" target="_blank" class="nav-link">Have an issue</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
 

  <!-- <nav class="navbar navbar-expand-lg fixed-top" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" href="#">SwarajPro</a>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">                   
                <li class="nav-item">
                  <a [routerLink]="['/governance']" *ngIf="(afAuth.authState | async)" class="nav-link"><i class="nc-icon nc-layout-11"></i>Governance</a>
                </li>    
                <li class="nav-item">
                  <a [routerLink]="['/login']" *ngIf="(afAuth.authState | async)" (click)="afAuth.signOut()" class="nav-link"><i class="nc-icon nc-layout-11"></i>Logout</a>
                  <a [routerLink]="['/login']" *ngIf="!(afAuth.authState | async)" class="nav-link"><i class="nc-icon nc-layout-11"></i>Login</a>                
                </li>                     
            </ul>
        </div>
    </div>
  </nav>
   -->