
<div class="modal-header">
  <h5 class="modal-title text-center">Menu</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"> 


      <!-- Menu Modal contents  -->
      <h5>Select theme type</h5> 
              
      <div *ngFor="let radiobutton of radioItems">
          <label>
          <input type="radio" name="options" (click)="onMenuOptionChanged(radiobutton)"
          [checked]="radiobutton === model.option"> {{radiobutton}}
          </label>
      </div>         
       
        <h6>
          <label>
          <input type="checkbox" [(ngModel)]="this.business.settings.enableTableBasedQRCode"> Enable table based QR codes
          </label> 
        </h6>
      
        <div class="form-group" *ngIf="this.business.settings.enableTableBasedQRCode">
          <label for="power">Select table count</label>
          <select class="form-control" required
            [(ngModel)]="this.business.settings.numberOfTables"
              ngControl="power" style="height: 100%;">
            <option *ngFor="let number of tableCount" [value]="number">{{number}}</option>
          </select> 
        </div>

      <!-- EnableTaxCalculation
      TaxSettings -->

        <h5>
          <label>
          <input type="checkbox" [(ngModel)]="this.business.settings.enableOnlineOrder" #ctrl="ngModel" > Enable online ordering
          </label>         
      </h5>

      
      <h5>
        <label>
        <input type="checkbox" [(ngModel)]="this.business.settings.enableOffers" #ctrl="ngModel" > Enable Offers
        </label>         
    </h5>

      
 
      <!-- Tax Calculation Section -->
    <h5>
        <label>
        <input type="checkbox" [(ngModel)]="this.business.settings.enableTaxCalculation" ngControl="enableTax"> Enable tax calculation
        </label>         
    </h5>
    <div class="form-group" *ngIf="this.business.settings.enableTaxCalculation">
      <button type="button" class="btn btn-success btn-link" (click)="onAddTaxInfo()">Add Tax Info</button>

      <div *ngFor="let settings of taxSettings; let i = index;">
        <div class="row">
          <div class="col">
              <input type="text" class="form-control"  
              placeholder="GST"   [(ngModel)]="settings.name"
              ngControl="name" >
          </div>
          <div class="col">
              <input type="text" class="form-control"  
              placeholder="%"   [(ngModel)]="settings.percentage"
              ngControl="percentage" >
          </div>    
        </div>
      </div>
    </div>
          <!-- Tax Calculation Section Ends -->


      <!-- CurrencyType -->

      <!-- Menu Modal contents ends -->


</div>

<div class="modal-footer">
  <div class="left-side">
      <button type="button" class="btn btn-success btn-link" (click)="onSubmit()">Save Changes</button>
  </div>
  <div class="divider"></div>
  <div class="right-side">
      <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Cancel</button>
  </div>
</div>