import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { FileObject, Item, Business } from '../../data-model/business.model';
import { BusinessService } from '../../services/business.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-verification-page',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.scss']
})
export class UserVerificationComponent  implements OnInit {
  @Input()
  business: Business;
  
  constructor(public afAuth: AngularFireAuth,
              public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              public businessService: BusinessService,
              private afs: AngularFirestore
              ){
 }
  
  ngOnInit() {  
  }  

  
  onSubmit() {
    this.activeModal.close('Close click');
  }

  
}
