
<div class="modal-header">
  <h5 class="modal-title text-center">QR Code</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="afAuth.authState | async as user" class="logout">    
  <div *ngIf= "business">
  
  <div class="form-group" style="width: 80%; margin-left: 10%;">
    <label for="power">Select table count</label>
    <select class="form-control" required
      [(ngModel)]="selectedTable"
        ngControl="power" ng-model="somemdoel" (change)="tableNumberChanged()"
        style="height: 100%;">
      <option *ngFor="let number of tableCount" [value]="number">{{number}}</option>
    </select>    
  </div>

  <div class="container" style="text-align: center;">
      <div #qrcodesection>
        <h2>{{business.name}}</h2>
        <div *ngIf="selectedTable!==0">
          <h5>Table number : {{selectedTable}} </h5>
        </div>
        <qrcode #parent [qrdata]="qrdata" [size]="256" [level]="'M'" ></qrcode>
        <h2>Scan Me</h2>
        <div *ngIf="selectedTable!==0">
            <p style="font-size:8px">{{this.qrdataFirst}}&table={{selectedTable}}</p>
        </div>
        <div *ngIf="selectedTable===0">
          <p style="font-size:8px">{{this.qrdataFirst}}</p>
          <p style="font-size:10px">Powered by SwarajPro</p>
        </div>
    </div>
    <button (click)="saveAsImage()">Download QR Code Image</button>
  </div>

  </div>
</div>
              
<div class="modal-footer">
  <div class="right-side">
      <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>


<div id="download">
  <img #canvas>
  <a #downloadLink></a>
</div>