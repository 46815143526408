import { Item, TaxSettings } from './business.model';

export interface Customer {
  customerId: string;
  orderIds : string[];
} 

export enum Status {
  CREATED,
  ACCEPTED,
  IN_PROCESS,
  READY,
  COMPLETED,
  CANCELLED,
  FAILED
}



export interface  OrderStatus {
    status: Status;
    changedBy: string;
    time: any;
    comment?: string;
    isActive: boolean;
    isCompleted: boolean;
}

export enum PaymentMethod {
  OFFLINE,
  ONLINE
}

export interface  PaymentDetails {
    id?: string;
    paymentMethod: PaymentMethod;
    updatedBy: string;
    time: any;
    baseAmmount: number;
    tax: number;
    total: number
}

export interface TaxCalc {
  ammount: number;
  taxSettings : TaxSettings;
}
 

export interface Order {
     id?: string;
     orderNumber: string;
     customerId: string;
     businessId: string;
     customer: string;
     orderStatus?: OrderStatus[];
     items: Item[];
     paymentDetails?: PaymentDetails;
     currentStatus: Status;
     createdTime: any;
     lastUpdatedTime: any;
     tableNumber?: string;
     subTotal: number;
     grandTotal: number;
     taxCalculation?: TaxCalc[];
}

