<input [(ngModel)]="name" #ctrl="ngModel" required>
<button type="button" class="btn btn-outline-success btn-sm" (click)="addItemType()"> Add type</button>
<button type="button" class="btn btn-outline-success btn-sm" (click)="deleteItemType()"> Delete type</button>

     <!-- Nav Bar -->
     <div class="nav-tabs-navigation">
        <div class="nav-tabs-wrapper">
            <ngb-tabset [activeId]="activeTab" (tabChange)="activeTab = $event.nextId">
                <ngb-tab *ngFor="let group of business.customMenu.itemGroup; index as i" title="{{group.name}}"  id="{{group.name}}"> 
                    <ng-template ngbTabContent>
                       <button type="button" class="btn btn-outline-success btn-sm" (click)="addItem(i)">Add item</button>
                        <div *ngFor="let item of business.customMenu.itemGroup[i].items" class="card card-custom" >
                          <app-custom-menu-item-page [item]="item" (itemChanged)="itemChangedHandler($event)" [business]='business'></app-custom-menu-item-page>
                        </div>
                    </ng-template>
                </ngb-tab>               
            </ngb-tabset>
        </div>
      </div>
      <!-- Nav Bar Ends -->
      
      