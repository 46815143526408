import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { AddressModel } from '../data-model/address.model';
import { Business, CustomMenu, Item, ItemGroup, Offers, SettingsOptions, StaticMenu } from '../data-model/business.model';
import { Hours } from '../data-model/hours.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  private business: Business;

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFirestore) {
    this.initialize();
  }

  /*
  *  initialize user business and other required info 
  */
  private initialize() {
    console.log("Initializing Business service");
    if (!this.business) {
      this.getUserBusiness().subscribe(business => {
        this.business = business || business[0] ? business[0] : undefined;
        console.log("User business set to : " + this.business);
      });
    }
  }

  // getter 
  public getBusiness() {
    return this.business;
  }

  /**
   * Creates a new business record making current user as owner
   */
  async createNewBusinessRecord(data: Business) {
    const docRef = this.db.collection('business').ref.doc();

    const user = await this.afAuth.currentUser;
    return this.db.collection('business').add({
      ...data,
      uid: user.uid,
      name: data.name,

    })
  }

  /**
   * Get all business's  owned by provided user id
   */
  getBusinessFromUserId(id: string) {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        // if (user) {
        return this.db
          .collection<Business>('business', ref =>
            ref.where('uid', '==', id)
          )
          .valueChanges({ idField: 'id' });
      }),
    );
  }

  /**
  * Get business from id
  */
  getBusinessFromId(id: string) {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        // if (user) {
        return this.db
          .collection<Business>('business', ref =>
            ref.where('id', '==', id)
          )
          .valueChanges({ idField: 'id' });
      }),
    );
  }

  /**
   * Get business owned by current user
   */
  getUserBusiness() {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db
            .collection<Business>('business', ref =>
              ref.where('uid', '==', user.uid)
            )
            .valueChanges({ idField: 'id' });
        } else {
          return [];
        }
      }),
    );
  }


  /**
   * Delete business record
   * TODO
   *  1. do not use this function & implement soft delete functionality
   */
  deleteBusiness(id: string) {
    return this.db
      .collection('business')
      .doc(id)
      .delete();
  }

  updateBusinessInfoSecure() {
    return this.db.collection('business').doc(this.business.id).update(this.business);
  }

  /*
  * Use Following setter to update business information
  * 1. Set updated values
  * 2. Call Update method
  *  TODO improve to avoid accidental updates on other fields
  */
  public setName(name: string) {
    this.business.name = name;
  }

  public setAddress(address: AddressModel) {
    this.business.address = address;
  }

  public setPhone(phone: string) {
    this.business.phone = phone;
  }

  public setSlogan(slogan: string) {
    this.business.slogan = slogan;
  }

  public setLogoUrl(logoUrl: string) {
    this.business.logoUrl = logoUrl;
  }

  // menu  
  public setCustomMenu(customMenu: CustomMenu) {
    this.business.customMenu = customMenu;
  }

  public setStaticMenu(staticMenu: StaticMenu) {
    this.business.staticMenu = staticMenu;
  }

  public setOffers(offers: Offers) {
    this.business.offers = offers;
  }

  public setHours(hours: Hours) {
    this.business.hours = hours;
  }

  public setSettingsOptions(settings: SettingsOptions) {
    this.business.settings = settings;
  }

  public setBusinessType(businessType: string) {
    this.business.businessType = businessType;
  }
}
