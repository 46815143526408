import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { Business } from '../../../data-model/business.model';
import { BusinessService } from '../../../services/business.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessDetailsComponent } from '../business-details/business-details.component';
import { MenuComponent } from '../menu/menu.component';
import { QRPageComponent } from '../qr-code/qr-code-page.component';
import { FileUploadService } from '../../../services/file-upload.service';
import { SettingsOptionsComponent } from '../settings-options/settings-options-page.component';

@Component({
  selector: 'app-business-profile-page',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss']
})
export class BusinessProfileComponent implements OnInit {

  business: Business;
  sub: Subscription;
  selectedFile: any;

  constructor(public afAuth: AngularFireAuth,
    private modalService: NgbModal,
    public businessService: BusinessService,
    public fileUploadService: FileUploadService) { }

  ngOnInit() {
    /*
   *   Retrive Business info for that user
   */
    this.sub = this.businessService
      .getUserBusiness()
      .subscribe(business => {

        // Do all required preprocessing of data retrived from business.
        // remove deleted  items indicated with priority -999
        this.business = business || business[0] ? business[0] : undefined;

        if (this.business) {

          this.business.customMenu.itemGroup.forEach(group => {
            group.items = group.items.filter((item) => {
              return !(item.priority < 0);
            })
          });

          if (!this.business.settings) {
            this.business.settings = {
              enableOnlineOrder: false,
              enableTableBasedQRCode: false,
              numberOfTables: 0,
              enableTaxCalculation: false
            }
          }

          if (!this.business.staticMenu) {
            this.business.staticMenu = {
              files: []
            }
          }

          this.business.staticMenu.files = this.business.staticMenu.files.filter((item) => {
            return !(item.priority < 0);
          });

        }
      });   
  }


  openBusinessDetails() {
    const modalRef = this.modalService.open(BusinessDetailsComponent);
    modalRef.componentInstance.business = this.business;
  }

  openMenuDetails() {
    const modalRef = this.modalService.open(MenuComponent);
    modalRef.componentInstance.business = this.business;
  }

  openQRCode() {
    const modalRef = this.modalService.open(QRPageComponent);
    modalRef.componentInstance.business = this.business;
  }

  openSelectThemeOption() {
    const modalRef = this.modalService.open(SettingsOptionsComponent);
    modalRef.componentInstance.business = this.business;
  }


  uploadPrifilePicFile() {
    this.fileUploadService.uploadFile(this.business, this.selectedFile).then((downloadURL) => {
      this.business.logoUrl = <string>downloadURL;
      this.businessService.setLogoUrl( this.business.logoUrl );
      this.businessService.updateBusinessInfoSecure();
      console.log("uploaded file url : " + downloadURL);
    }).catch((err) => { console.log("uploaded error : " + err) });
  }


  // detech for file upload 
  detectFiles(event) {
    this.selectedFile = event.target.files[0];

    //-- upload selected file and get url to save 
    if (this.selectedFile) {
      this.uploadPrifilePicFile()
    }
  }

  getPic() {
    if (!this.business.logoUrl) {
      return 'none';
    }

    return 'url(' + this.business.logoUrl + ')';
  }

  deletePic() {
    this.business.logoUrl = '';
  }

}
