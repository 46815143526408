import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UnitsOfMeasurement } from '../data-model/constants';

@Injectable()
export class MySharedService {

  // setting goes here
  theme : string;
  private enableOnlineOrder: boolean = false;  
  private byPassUserValidationWhileOrdering: boolean = false;  
  
  // set other configs similar to table number which are 
  // not stored in db but based on user actions
  tableNumber : any
  enableTaxCalculation: any;

  setEnableOnlineOrder (isEnabled: boolean) {
    this.enableOnlineOrder = isEnabled;
  }

  getEnableOnlineOrder() {
    return this.enableOnlineOrder;
  }

  setTableNumber (tableNumber: number) {
    this.tableNumber = tableNumber;
  }

  getTableNumber() {
    return this.tableNumber;
  }

  setEnableTaxCalculation (isEnabled: boolean) {
    this.enableTaxCalculation = isEnabled;
  }

  getEnableTaxCalculation() {
    return this.enableTaxCalculation;
  }

  // -- Bypass User validation
  //    Use Flag for setting these values. 
  //  Usage : Testing only  [ Do not use it for production ]
  //  TODO block usage for all user except test users 
 
  setByPassUserValidationWhileOrdering (isEnabled: boolean) {
    this.byPassUserValidationWhileOrdering = isEnabled;
  }

  getByPassUserValidationWhileOrdering() {
    return this.byPassUserValidationWhileOrdering;
  }

  getUnitsOfMeasurement() {
    var units: string[] = [];
    units.push(UnitsOfMeasurement.grams);
    units.push(UnitsOfMeasurement.kilograms);
    units.push(UnitsOfMeasurement.liters);
    units.push(UnitsOfMeasurement.milliliters);
    return units;
  }

 }