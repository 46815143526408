import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { FileObject, Item, Business } from '../../../data-model/business.model';
import { BusinessService } from '../../../services/business.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent  implements OnInit {
  @Input()
  business: Business;

  radioTitle: string;
  radioItems: Array<string>;
  model   = {option: 'Upload existing'};
  @Output() menuSelectionChanged: EventEmitter<string> =   new EventEmitter();


  constructor(public afAuth: AngularFireAuth,
              public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              public businessService: BusinessService,
              private afs: AngularFirestore){
        
    this.radioTitle = 'Radio Button in Angular';
    this.radioItems = ['Upload existing', 'Create custom'];
  }
  
  ngOnInit() {  
    this.model.option = this.radioItems[this.business.settings?.selectedLayout];
  }  

  onMenuOptionChanged(radiobutton : string) {
    this.model.option = radiobutton;
    
    if (this.model.option === this.radioItems[1]) {
       this.business.settings.selectedLayout = 1;
      } else  {
        this.business.settings.selectedLayout = 0;
    }
    this.menuSelectionChanged.emit(radiobutton);
  }

   // -- item values is update
   itemChangedHandler(updatedItem: Item) {
    // Emite to parent
    this.updateRestaurantInfo();
  }
  
  onSubmit() {
    // TODO save user preference for menu type
    
    // update restaurant date

    this.updateRestaurantInfo();
    this.activeModal.close('Close click');
  }

  updateRestaurantInfo(){
    this.businessService.setCustomMenu(this.business.customMenu);
    this.businessService.setStaticMenu(this.business.staticMenu);
    this.businessService.updateBusinessInfoSecure();
  }

  fileChangedHandler(file: FileObject){
    this.updateRestaurantInfo();
  }

}
