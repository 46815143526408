import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { Business } from '../../../data-model/business.model';
import { BusinessService } from '../../../services/business.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GovernanceService } from '../../../services/governance.service';

@Component({
  selector: 'app-business-details-page',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss']
})
export class BusinessDetailsComponent implements OnInit {
  @Input()
  business: Business;

  focus: any;
  focus1: any;
  form: FormGroup;
  isNewBusiness: boolean;

  constructor(public afAuth: AngularFireAuth,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public businessService: BusinessService,
    private afs: AngularFirestore,
    private governanceService: GovernanceService) {

  }

  closeResult: string;

  ngOnInit() {
    this.form = this.fb.group({
      businessName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postCode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      slogan: ['', []]
    });

    // -- initailize form controllers with existing values
    if (this.business) {
      this.isNewBusiness = false;
      this.setFormData(this.business);
    } else {
      this.isNewBusiness = true;

    }
  }

  async onSubmit() {

    const address = {
      addressLine1: this.addressLine1.value,
      addressLine2: this.addressLine2.value,
      city: this.city.value,
      postCode: this.postCode.value,
      country: this.country.value,
      state: this.state.value,

    }

    const businessNew: Business = {
      name: this.businessName.value,
      phone: this.phone.value,
      address: address,
      slogan: this.slogan.value
    };


    try {
      if (this.isNewBusiness) {

        this.businessService.createNewBusinessRecord(businessNew).then(async (ref) => {        
          // TODO gather things needs to after creating new business record
        });

      } else {
        // TODO  REMOVE  update all required here
        // businessNew.customMenu = this.business.customMenu;
        // businessNew.staticMenu = this.business.staticMenu;        
        this.businessService.setName(businessNew.name);
        this.businessService.setPhone(businessNew.phone);
        this.businessService.setAddress(businessNew.address);
        this.businessService.setSlogan(businessNew.slogan);
        this.businessService.updateBusinessInfoSecure();
      }
      this.activeModal.close('Close click');
    }
    catch (err) {
      console.log(err);
    }
  }

  private setFormData(business: Business) {

    let businessName, phone, addressLine1, addressLine2, city, state, postCode, country, slogan;

    if (this.business) {
      businessName = this.business.name;
      slogan = this.business.slogan;
      phone = this.business.phone;
      if (this.business.address) {
        addressLine1 = this.business.address.addressLine1;
        addressLine2 = this.business.address.addressLine2;
        city = this.business.address.city;
        postCode = this.business.address.postCode;
        country = this.business.address.country;
        state = this.business.address.state;
      }
    }

    this.form.setValue({
      'businessName': businessName,
      'phone': phone,
      'addressLine1': addressLine1,
      'addressLine2': addressLine2,
      'city': city,
      'state': state,
      'postCode': postCode,
      'country': country,
      'slogan': slogan
    });
  }

  get businessName() {
    return this.form.get('businessName');
  }

  get phone() {
    return this.form.get('phone');
  }
  get addressLine1() {
    return this.form.get('addressLine1');
  }
  get addressLine2() {
    return this.form.get('addressLine2');
  }
  get city() {
    return this.form.get('city');
  }
  get state() {
    return this.form.get('state');
  }
  get postCode() {
    return this.form.get('postCode');
  }

  get country() {
    return this.form.get('country');
  }

  get slogan() {
    return this.form.get('slogan');
  }
}
