<div class="section text-center">
  <div class="container">
    <div *ngIf="business">
      <h6>Upload your files here.</h6>
      <div class="row">
        <div class="col-md-4" *ngFor="let file of files">
          <div class="form-group row">

            <div class="image-input image-input-outline" id="kt_profile_avatar" [class.image-input-empty]="!file.url"
              [style.backgroundImage]="'url(./assets/media/users/menu.png)'">
              <div class="image-input-wrapper" [style.backgroundImage]="getPic(file)"></div>
              <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                <i class="fa fa-pen icon-sm text-muted"></i>

                <input type="file" name="profile_avatar" (change)="detectFiles($event, file)"
                  accept=".png, .jpg, .jpeg" />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span *ngIf="file.url" class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                (click)="deleteFile(file)" data-action="remove" data-toggle="tooltip" title=""
                data-original-title="Remove avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>