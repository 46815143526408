import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { Business, SettingsOptions, TaxSettings } from '../../../data-model/business.model';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder } from '@angular/forms';
import { BusinessService } from '../../../services/business.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-settings-options-page',
  templateUrl: './settings-options-page.component.html',
  styleUrls: ['./settings-options-page.component.scss']
})
export class SettingsOptionsComponent implements OnInit {
  @Input()
  business: Business;
  radioTitle: string;
  radioItems: Array<string>;
  model = { option: 'default' };
  tableCount: any;

  taxSettings: TaxSettings[] = [];

  @Output() menuSelectionChanged: EventEmitter<string> = new EventEmitter();

  constructor(public afAuth: AngularFireAuth,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public businessService: BusinessService,
    private afs: AngularFirestore) {

    this.radioTitle = 'Radio Button in Angular';
    this.radioItems = ['default', 'touche'];

    this.tableCount = Array(100);
    for (let i = 0; i < 100; i++) {
      this.tableCount[i] = i;
    }


  }
  ngOnInit() {
    this.model.option = this.business.settings?.selectedTheme ? this.business.settings?.selectedTheme : 'default';

    // check settings are present or not
    if (!this.business.settings) {
      const newSettings: SettingsOptions = {
        enableOnlineOrder: false,
        enableTableBasedQRCode: false,
        numberOfTables: 0,
        enableTaxCalculation: false,
        taxSettings: []
      }
      this.business.settings = newSettings;
    }

    this.taxSettings = this.business.settings.taxSettings ? this.business.settings.taxSettings : [];
  }

  onMenuOptionChanged(radiobutton: string) {
    this.model.option = radiobutton;

    if (this.model.option === this.radioItems[0]) {
      this.business.settings.selectedTheme = "default";
    } else {
      this.business.settings.selectedTheme = "touche";
    }
    // this.menuSelectionChanged.emit(radiobutton);
  }

  onSubmit() {
    // TODO save user preference for menu type

    // Tax  setting s updates

    this.business.settings.taxSettings = this.taxSettings.filter(item => {
      return item.percentage > 0 && item.name !== '';
    });

    // update business date

    this.updateBusinessInfo();
    this.activeModal.close('Close click');
  }

  updateBusinessInfo() {
    this.businessService.setSettingsOptions(this.business.settings);
    this.businessService.updateBusinessInfoSecure();
  }

  onAddTaxInfo() {
    let newSettings: TaxSettings = { name: '', percentage: 0 };
    this.taxSettings.push(newSettings);
  }
}
