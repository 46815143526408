
<div class="modal-header">
    <h5 class="modal-title text-center">Order</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body"> 

        <h6>Identify Verification required for placing order</h6> 
        <div class="container">
            <firebase-ui></firebase-ui>
        </div>

        <div *ngIf="(afAuth.authState  | async)">
            Wonderful !! Verification completed. Please complete your order.
        </div>
        

</div>

<div class="modal-footer">   
    <div class="right-side">
        <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Ok</button>
    </div>
</div>