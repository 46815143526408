<!-- <mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>     
      <a mat-list-item routerLink="/" (click)="drawer.close()">Home</a>
      <a mat-list-item routerLink="/login" (click)="drawer.close()">Login</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button 
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">


        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="logo" routerLink="/">🔥 Angular Firestarter</span>

      <span class="fill-space"></span>
        <div>
        <a mat-button routerLink="/login" *ngIf="!(afAuth.authState | async)">🔑 Login</a>
        <a mat-button routerLink="/login" *ngIf="afAuth.authState | async as user" >
          <img class="avatar" [src]="user.photoURL || 'assets/default-user.svg'">
          Profile
        </a>

      </div>     
    </mat-toolbar>

    <ng-content></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container> -->

<div *ngIf="!hide"> 

  <nav class="navbar navbar-expand-lg fixed-top" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" href="#">SwarajPro</a>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">                   
                <li class="nav-item">
                  <a [routerLink]="['/governance']" *ngIf="(afAuth.authState | async)" class="nav-link"><i class="nc-icon nc-layout-11"></i>Governance</a>
                </li>    
                <li class="nav-item">
                  <a [routerLink]="['/login']" *ngIf="(afAuth.authState | async)" (click)="afAuth.signOut()" class="nav-link"><i class="nc-icon nc-layout-11"></i>Logout</a>
                  <a [routerLink]="['/login']" *ngIf="!(afAuth.authState | async)" class="nav-link"><i class="nc-icon nc-layout-11"></i>Login</a>                
                </li>                     
            </ul>
        </div>
    </div>
  </nav>
  
</div>
<ng-content></ng-content>
